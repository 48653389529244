/* eslint-disable max-len */
import axios from 'axios';
import { getVatRate } from '@/utils/product-service'; // eslint-disable-line  
import store from '../store'; // eslint-disable-line

export function roundPrice(value) {
  return Math.round(((value + Number.EPSILON) * 100) / 100);
}

export function vatRate(id) {
  const [vatId] = store.getters['vats/vatById'](id);
  return id ? vatId.rate : 0;
}

export function vatMultiplier(id) {
  return id ? 1 + (vatRate(id) / 100) : 1;
}

export function priceWithTaxes(price, vatId) {
  return roundPrice((price * vatMultiplier(vatId)));
}

export function setProductTotals(givenProduct) {
  const product = givenProduct;
  product.totalPrice = product.isUnderCampaign ? product.campaignPrice * product.quantity : product.price * product.quantity;
  product.totalPriceWithVat = (product.totalPrice * (100 + getVatRate(product.vatId))) / 100;
  const roundedTotalFromSinglePrice = Math.round(product.singlePriceWithVat) * product.quantity;
  const roundedTotalPriceWithVat = Math.floor(product.totalPriceWithVat);
  product.roundedTotalPriceWithVat = roundedTotalPriceWithVat;
  if (roundedTotalPriceWithVat - roundedTotalFromSinglePrice === 1) {
    product.roundedTotalPriceWithVat = roundedTotalFromSinglePrice;
  }
  return product;
}

export function updateProductPrices(givenProduct, discountRule) {
  const product = givenProduct;
  product.is_discounted = true;
  product.discount_rate = discountRule.rate;
  product.discounted_price = product.price - ((product.price * product.discount_rate) / 100);
  product.discounted_price_with_vat = (product.discounted_price * (100 + product.getVatRate(product.vat_id))) / 100;
  product.totalPrice = product.is_discounted ? product.discounted_price * product.quantity : product.price * product.quantity;
  product.totalPriceWithVat = (product.totalPrice * (100 + product.getVatRate(product.vat_id))) / 100;
  product.singlePriceWithVat = product.is_discounted ? (product.discounted_price * (100 + product.getVatRate(product.vat_id))) / 100 : (product.price * (100 + product.getVatRate(product.vat_id))) / 100;
  product.vat = product.totalPriceWithVat - product.totalPrice;
  const roundedTotalFromSinglePrice = Math.round(product.singlePriceWithVat) * product.quantity;
  const roundedTotalPriceWithVat = Math.floor(product.totalPriceWithVat);
  product.roundedTotalPriceWithVat = roundedTotalPriceWithVat;
  if (roundedTotalPriceWithVat - roundedTotalFromSinglePrice === 1) {
    product.roundedTotalPriceWithVat = roundedTotalFromSinglePrice;
  }

  return product;
}

export function setNewQuantity(givenProduct, payloadQuantity, quantityToAdd) {
  const product = givenProduct;
  if (payloadQuantity === '+') {
    product.quantity += quantityToAdd;
  } else {
    product.quantity = payloadQuantity;
  }
  return product;
}

export function checkProductQuantity(product) {
  let { quantity } = product;
  if (product.min_quantity && quantity < product.min_quantity) {
    quantity = product.min_quantity;
  }
  if (product.available_quantity && product.quantity > product.available_quantity) {
    quantity = product.available_quantity;
  }
  if (product.max_quantity && quantity > product.max_quantity) {
    quantity = product.max_quantity;
  }
  return quantity;
}

export function discountRowSelection(price, discounts) {
  let totalDiscount = 0;
  let tempPrice = price;
  let discountApplied = discounts;
  let blockOthers = false;

  if (discountApplied.length === 0) return { discount: 0, blockOthers };

  const discountBlockOthers = discountApplied.filter((discount) => discount.blockOthers);
  if (discountBlockOthers.length > 0) {
    discountApplied = discountBlockOthers;
    blockOthers = true;
  }

  discountApplied.sort((a, b) => a.priority - b.priority);
  for(const discount of discountApplied ) { // eslint-disable-line
    totalDiscount += ((tempPrice * discount.rate) / 100);
    tempPrice *= (1 - (discount.rate / 100));
  }

  return { discount: totalDiscount, blockOthers };
}

export function calculateDiscountedPrice(price, customerDiscount, discountApplied) {
  const discountCustomer = price * (customerDiscount / 100);
  const discountRowToApply = discountRowSelection(price, discountApplied);
  const discountRow = discountRowToApply.discount;
  const discountedPrice = discountRowToApply.blockOthers ? price - discountRow : price - (discountCustomer + discountRow); // eslint-disable-line
  return roundPrice(discountedPrice);
}

export function calculateShippingPrice(selectedProducts, shippingAddress) {
  const productsWitShippingPrice = selectedProducts
    .filter((product) => !!product.shippingPrice)
    .sort((a, b) => b.shippingPrice - a.shippingPrice)[0];
  const destination = store.getters['shops/shop'].country === shippingAddress.country ? 'national' : 'international';
  const shippingMethod = store.getters['shippingMethods/shippingMethods'].find((method) => method.slug === destination);
  const totalDiscountedPrice = selectedProducts.reduce((total, product) => total + product.totalPrice, 0);
  // eslint-disable-next-line
  const shippingPrice = productsWitShippingPrice && productsWitShippingPrice.shippingPrice
    ? productsWitShippingPrice.shippingPrice
    : totalDiscountedPrice < shippingMethod.free_shipping
      ? shippingMethod.price
      : 0;

  return {
    shippingPrice,
    shippingWithTaxes: priceWithTaxes(shippingPrice, shippingMethod.vat_id),
    shippingMethodId: shippingMethod.id,
    destination,
  };
}

export function getAvailablePayments() {
  return axios.get('/api/available-payments');
}
