import axios from 'axios';

export default {
  namespaced: true,

  state: {
    campaigns: [],
    campaign: {},
    campaignRows: [],
  },

  getters: {
    campaigns(state) {
      return state.campaigns;
    },
    campaign(state) {
      return state.campaign;
    },
    campaignRows(state) {
      return state.campaignRows;
    },
    homeCampaigns(state) {
      return state.campaigns.filter((campaign) => campaign.in_home);
    },
  },

  mutations: {
    setCampaigns(state, value) {
      state.campaigns = value;
    },
    setCampaign(state, value) {
      state.campaign = value;
    },
    setCampaignRows(state, value) {
      state.campaignRows = value;
    },
  },

  actions: {
    async getCampaigns({ commit }) {
      const { data } = await axios.get('/api/campaigns');
      commit('setCampaigns', data);
    },
    async getCampaign({ commit }, campaignId) {
      const { data } = await axios.get(`/api/campaigns/${campaignId}`);
      commit('setCampaign', data);
    },
    async postCampaign({ commit }, campaign) {
      const { data } = await axios.post('/api/campaigns', campaign);
      commit('setCampaign', data);
      return data;
    },
    // eslint-disable-next-line no-unused-vars
    async postCampaignRows({ dispatch }, { campaignId, priceListRows }) {
      const { data } = await axios.post(`/api/campaigns/${campaignId}/rows`, priceListRows);
      return data;
    },
    async deletePriceListRow({ dispatch }, { campaignPriceListRowId, campaignPriceListRow }) {
      await axios.delete(`/api/campaign-rows/${campaignPriceListRowId}/delete`, campaignPriceListRow);
      dispatch('getCampaigns');
    },
    async putCampaign({ commit }, { id, ...remaining }) { // eslint-disable-line
      const { data } = await axios.put(`/api/campaign/${id}`, remaining);
      return data;
    },
  },
};
