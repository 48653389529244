import axios from 'axios';

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    changedLocale: false,
    backofficeUser: null,
  },

  getters: {
    authenticated(state) {
      return state.authenticated;
    },

    isSuperAdmin(state) {
      return state.user.is_super_admin;
    },

    user(state) {
      return state.user;
    },

    // userPermissions(state) {
    //   return state.user.get_permissions_via_company_roles.map(({ name }) => name);
    // },

    changedLocale(state) {
      return state.changedLocale;
    },

    isExtraCEE(state) {
      return !!state.user && !!state.user.customer ? state.user.customer.extraCee : false;
    },
  },

  mutations: {
    setAuthenticated(state, value) {
      state.authenticated = value;
    },

    setUser(state, value) {
      state.user = value;
    },
  },

  actions: {
    async postUser({ commit }, credentials) { // eslint-disable-line
      const { data } = await axios.post('/api/users', credentials);
      return data;
    },

    async register({ dispatch }, credentials) {
      await axios.post('/register', credentials);
      return dispatch('me');
    },

    async signIn({ dispatch }, credentials) {
      await axios.get('/sanctum/csrf-cookie');
      await axios.post('/login', credentials);

      return dispatch('me');
    },

    async signOut({ dispatch }) {
      await axios.post('/logout');

      dispatch('clearCredentials');
    },

    async resetPasswordLinkRequest({ dispatch }, email) { // eslint-disable-line
      await axios.post(axios.post('/forgot-password', { email }));
    },

    clearCredentials({ commit }) {
      commit('setAuthenticated', false);
      commit('setUser', null);
    },

    me({ state, commit, dispatch }) {
      if (state.user) {
        return state.user;
      }
      return axios.get('/api/user')
        .then(({ data }) => {
          commit('setAuthenticated', true);
          commit('setUser', data);
          dispatch('cart/setUser', data.id, { root: true });
          dispatch('customers/replaceCustomer', data.customer, { root: true });
          if (data.customer) {
            dispatch('customers/getOpenOrderRows', data.customer.id, { root: true });
            dispatch('customers/getOnShippingRows', data.customer.id, { root: true });
          }
        }).catch(() => {
          dispatch('clearCredentials');
        });
    },

    auth({ commit, dispatch }) {
      return axios.get('/api/user')
        .then(({ data }) => {
          commit('setUser', null);
          commit('setAuthenticated', true);
          commit('setUser', data);
          dispatch('cart/setUser', data.id, { root: true });
        }).catch(() => {
          commit('setAuthenticated', false);
          commit('setUser', null);
        });
    },

    async postRequestBearerToken({ state }, name) {
      const { data } = await axios.post(`/api/users/${state.user.id}/request-token`, { name });
      return data.token;
    },

    async getUserTokens({ state }) {
      const { data } = await axios.get(`/api/users/${state.user.id}/tokens`);
      return data;
    },

    async revokeUserToken({ state }, id) {
      await axios.delete(`/api/users/${state.user.id}/${id}/revoke`, { id });
    },

    async updatePassword({ state }, credentials) {
      const { data } = await axios.put(`/api/users/${state.user.id}/password`, credentials);
      return data;
    },
  },
};
